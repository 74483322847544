<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Conversaciones - Whatsapp Marketing</h4>
            <div class="small text-muted">Administración de Conversaciones</div>
          </b-col>

          <b-col sm="5">
            <b-col sm="12" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>  
        </b-row>
      </b-card>
      
      <b-card header-tag="header" footer-tag="footer">
        <b-row v-if="table.mostrarFiltros">
          <b-col sm="10">
            <b-row>
              <b-col sm="4" md="3">
                <b-form-group>
                  <b-input-group>
                    <b-form-input type="text" size="sm" placeholder="Ingrese un nombre o teléfono" v-model="filters.name" v-on:keyup.enter.native="load"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col sm="4" md="3">
                <b-button variant="outline-dark" @click="load()" size="sm" class="mt-1">
                  <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-table class="mb-0"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="table.items"
                    :fields="table.fields"
                    :filter="table.filter"
                    :current-page="table.currentPage"
                    :per-page="table.perPage" 
                    selectable
                    select-mode="single"
                    @row-selected="onRowSelected"                      
                    v-if="table.items.length">
              <template v-slot:row-details="row">
                <b-card>
                  <b-row>
                    <b-col>
                      <b-table class="mb-0"
                          responsive="sm"
                          head-variant="dark"
                          :hover="true"
                          :small="true"
                          :fixed="false"
                          :items="tableSub.items"
                          :fields="tableSub.fields"                            
                          v-if="tableSub.items.length">                                                                                     

                        <template v-slot:cell(date)="data">
                            {{ moment.unix(data.item.time).format("DD/MM/YYYY") }}
                        </template>  

                        <template v-slot:cell(time)="data">
                            {{ moment.unix(data.item.time).format("hh:mm a") }}
                        </template>  
                        
                        <template v-slot:cell(body)="data">
                            <div v-if="data.item.type == 'chat'">
                              {{data.item.body}}
                            </div>
                            
                            <div v-else>
                              <div v-if="data.item.body.substr(0,8) == 'https://'">
                                <a :href="data.item.body" target="_blank">{{ data.item.body }}</a>
                              </div>
                              <div v-else>
                                {{ data.item.body }}
                              </div>
                            </div>  
                        </template>  

                      </b-table>
                      <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
                    </b-col>
                  </b-row>
                </b-card>
              </template>

               <template v-slot:cell(senderName)="data">
                  {{ data.item.senderName }}
                </template> 

                <template v-slot:cell(author)="data">
                  {{ data.item.author.substr(0, data.item.author.indexOf('@')) }}
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>
          <b-col>
            <nav v-if="table.tablePaginate">
                  <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills=""
                                :total-rows="table.tablePaginate.total"
                                :per-page="table.tablePaginate.per_page"
                                v-model="table.currentPage"
                                @input="load()" />
            </nav>
          </b-col>            
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import Imagen from '@/components/inc/image/image' 
  import moment from 'moment'
  
  export default {     
    data: () => {
      return {      
        access: {
          module_id: Modules.WHATSAPP_MARKETING,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudConversations',
          elements: {}
        }, 
        table : {
          items: [],
          fields: [
            {key: 'senderName', label: 'Nombre'},            
            {key: 'author', label: 'Número Whatsapp'},
          ],
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 0,
          tablePaginate: null,    
        },    
        tableSub : {
          items: [],
          filters: null,
          fields: [
            {key: 'date', label: 'Fecha', sortable: true},
            {key: 'time', label: 'Hora', sortable: true},
            {key: 'body', label: 'Mensaje', sortable: true},            
          ],
          filter: null,
          mostrarFiltros: false,
          currentPage: 1,
          perPage: 50,
        },
        arr: {
          conversations: [],          
        },  
        filters: {          
          name: '', 
        },
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.load()      
    },
    methods: {
      getRowCount (items) {
        return items.length
      }, 
      onRowSelected(item) {
        this.arr.conversations.forEach((element, index) => {
          if(item.length){
            if(element.id == item[0].id) {
              this.openDetail(index)
            }
          }
        }) 
      },     
      openDetail(index) {
        this.table.rowSelected = index
        this.load()
      }, 
      load() {
        let loader = this.$loading.show();
        var result = serviceAPI.obtenerWhatsappMarketingConversations(this.filters, this.table.currentPage)

        result.then((response) => {
          var data = response.data          
          this.table.items = data.data 
          this.table.tablePaginate = data    
          this.arr.conversations = data.data
          
          if(this.table.rowSelected!=null) {
            this.table.items[this.table.rowSelected]._showDetails = true

            if(this.table.items[this.table.rowSelected].conversations) {
              this.tableSub.items = this.table.items[this.table.rowSelected].conversations
            }
          }

          loader.hide() 
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });        
      },
    }    
  }
</script>